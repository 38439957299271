<template>
  <div class="w-100 pt-4">
    <async-list
      :is-all-items-loaded="isAllItemsLoaded"
      without-loader
      class="h-100"
      @load-more-items="loadMoreItems"
    >
      <b-container fluid="xl">
        <div class="mb-3">
          <div class="h3 mb-0">
            Обращения
          </div>
        </div>

        <appeals-search-panel
          v-model="filter"
          class="mb-4"
          @search="appealsFetch(true)"
        />

        <div
          v-if="!totalCount && searchDone && !isLoading"
          class="bg-white rounded border p-3"
        >
          Ничего не найдено
        </div>

        <div v-if="totalCount">
          <div class="d-flex">
            <div class="h6">
              Результаты поиска
            </div>

            <!-- <span class="result-count">
              Найдено: {{ totalCount }}
            </span> -->
          </div>
        </div>

        <div
          v-for="(appeal, index) in appeals"
          :key="index"
          class="mt-3 mb-4"
        >
          <AppealInfoNew :appeal="appeal" />
        </div>

        <div v-if="isLoading">
          <b-col
            v-for="item in LIMIT"
            :key="item"
            cols="12"
            class="mt-3 p-0 rounded"
          >
            <b-skeleton-img
              no-aspect
              height="183px"
            />
          </b-col>
        </div>
      </b-container>
    </async-list>
  </div>
</template>

<script>
import debounce from 'lodash/debounce';
import { mapGetters } from 'vuex';
import {
  formatISO,
} from '@evd3v/date-fns';
import { mixinRoles } from '@/mixins';
import { dateWithoutTime } from '@/helpers/utils';
import Bus from '@/eventBus';

import AsyncList from '@/components/AsyncList';
import AppealInfoNew from '@/components/Appeals/AppealInfoNew';
import AppealsSearchPanel from '@/components/Appeals/AppealsSearchPanel';
import { appealsService } from '@/services';

const LIMIT = 15;
const FETCH_DELAY = 700;
export default {
  name: 'Appeals',
  page: {
    title: 'CRM Doctis - Обращения',
  },
  components: {
    AsyncList,
    AppealInfoNew,
    AppealsSearchPanel, // ? регистрируем, но не используем
  },
  mixins: [mixinRoles],
  data() {
    return {
      LIMIT,
      isAllItemsLoaded: false,
      isLoading: false,
      searchDone: false,
      limit: LIMIT,
      isError: false,
      filter: {
        id: null,
        patientFio: '',
        phoneNumber: null,
        creationDateFrom: null,
        creationDateTo: null,
        purpose: [],
        category: [],
        type: null,
        status: [],
        responsibleId: [],
        clinics: [],
        policyNumber: null,
        insuranceCompanyId: [],
        insuranceProgramId: [],
        insuranceSubprogramId: [],
        hasReconciliation: null,
      },
      appeals: [],
    };
  },
  computed: {
    ...mapGetters({
      appealCreatedTrigger: 'Appeal/GET_APPEAL_CREATED_TRIGGER',
    }),
    totalCount() {
      return this.appeals.length;
    },
  },
  watch: {
    filter: {
      handler(filter) {
        localStorage.setItem('appeals-filter', JSON.stringify(filter));
      },
      deep: true,
    },
    async appealCreatedTrigger() {
      await this.appealsFetch(true);
    },
  },
  created() {
    this.appealsFetchDebounced = debounce(this.appealsFetch, FETCH_DELAY);
    Bus.$on('appeals:reset-filter', this.resetFilter);
    Bus.$on('appeals:fetch', this.appealsFetch);

    if (localStorage.getItem('appeals-filter')) {
      try {
        const filter = JSON.parse(localStorage.getItem('appeals-filter'));
        const { creationDateTo, creationDateFrom } = filter;
        this.filter = {
          ...filter,
          creationDateTo: creationDateTo ? new Date(filter.creationDateTo) : null,
          creationDateFrom: creationDateFrom ? new Date(filter.creationDateFrom) : null,
        };
      } catch (e) {
        localStorage.removeItem('appeals-filter');
      }
    }
  },
  beforeDestroy() {
    Bus.$off('appeals:reset-filter', this.resetFilter);
    Bus.$off('appeals:fetch', this.appealsFetch);
  },
  methods: {
    openEditModal() {
      // Bus.$emit('open-modal:patient-edit', null);
    },

    async appealsFetch(clear) {
      if (
        (this.isLoading
        || this.isError)
        && !clear
      ) return;
      if (clear) this.appeals = [];

      this.isAllItemsLoaded = false;
      this.isLoading = true;

      try {
        const prevTotal = clear ? 0 : this.totalCount;

        const {
          id,
          policyNumber,
          patientFio,
          creationDateFrom,
          creationDateTo,
          category,
          purpose,
          phoneNumber,
          status,
          responsibleId,
          clinics,
          insuranceCompanyId,
          insuranceProgramId,
          insuranceSubprogramId,
          hasReconciliation,
        } = this.filter;
        const requestObject = {
          skip: prevTotal,
          take: this.limit,
          clear: !!clear,
          // ...this.filter,
          id,
          patientFio,
          phoneNumber,
          policyNumber,
          hasReconciliation,
          creationDateFrom: creationDateFrom ? dateWithoutTime(formatISO(creationDateFrom)) : null,
          creationDateTo: creationDateTo ? formatISO(new Date(creationDateTo).setHours(23, 59, 0, 0)) : null,
          purposes: purpose ? purpose.map((p) => p.id) : null,
          categories: category ? category.map((c) => c.id) : null,
          statuses: status ? status.map((s) => s.id) : null,
          responsibleIds: responsibleId ? responsibleId.map((r) => r.id) : null,
          clinicIds: clinics ? clinics.map((clinic) => clinic.id) : null,
          insuranceCompanyIds: insuranceCompanyId ? insuranceCompanyId.map((company) => company.id) : null,
          insuranceProgramId: insuranceProgramId ? insuranceProgramId.map((program) => program.id) : null,
          insuranceSubprogramId: insuranceSubprogramId ? insuranceSubprogramId.map((subProgram) => subProgram.id) : null,
        };
        const data = await this.searchNewAppeal(requestObject);
        this.appeals = [...this.appeals, ...data.item1];
        if (prevTotal + this.limit > this.totalCount) {
          this.isAllItemsLoaded = true;
        }
      } catch (e) {
        this.isAllItemsLoaded = true;
        console.warn(e);
        if (e?.response?.status === 500) {
          this.isError = true;
        }
      } finally {
        this.isLoading = false;
        this.searchDone = true;
      }
    },
    loadMoreItems() {
      if (this.isLoading) return;

      this.appealsFetch(false);
    },
    openExportappeal() {
      // Bus.$emit('open-modal:patient-add');
    },
    resetFilter() {
      localStorage.removeItem('appeals-filter');
      this.filter = {
        patientFio: '',
        phoneNumber: null,
        creationDateFrom: null,
        creationDateTo: null,
        type: null,
        status: [],
        purpose: [],
        category: [],
        responsibleId: [],
        clinics: [],
        insuranceCompanyId: [],
        insuranceProgramId: [],
        insuranceSubprogramId: [],
      };
      this.appeals = [];
    },
    async searchNewAppeal(requestObject) {
      try {
        const data = await appealsService.search(requestObject);
        return data;
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.appeals {
  display: flex;
  flex-direction: column;
  width: 100%;
}

::v-deep.icon-user {
  margin-right: 15px;
  path {
    fill: $blue;
  }
}

::v-deep.crm-info-wrapper {
  padding-top: 30px;
  padding-bottom: 30px;
}

.appeal-list {
  height: 100vh;
  transition: height 0.3s linear;
  box-sizing: border-box;
}

.result-row {
  flex: 1;
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
}

.appeals-search-panel {
  margin-top: 20px;
}
</style>
